import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "on", "off" ]

  connect() {
    this.toggle()
  }

  toggle() {
    this.onTarget.classList.toggle("d-none", !this.inputTarget.checked)
    this.offTarget.classList.toggle("d-none", this.inputTarget.checked)
  }
}
