import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input" ]

  validate(event) {
    if (/\d{10,}/.test(this.inputTarget.value)) {
      const response = confirm(`This looks like a Trainer Code and not your Trainer Nickname. Is ${this.inputTarget.value} really your Trainer Nickname?`)
      if (response == false) {
        event.preventDefault()
        this.inputTarget.focus()
      }
    }
  }
}
