import { Controller } from "stimulus"

var hidden = "hidden"
if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
  hidden = "hidden"
} else if (typeof document.msHidden !== "undefined") {
  hidden = "msHidden"
} else if (typeof document.webkitHidden !== "undefined") {
  hidden = "webkitHidden"
}

export default class extends Controller {

  connect() {
    this.refreshTime = this.data.get("refresh") || 3500
    this.tick()
  }

  disconnect() {
    if (this.ticker) {
      clearTimeout(this.ticker)
      this.ticker = null
    }
  }

  tick() {
    this.checkLockButton()
    this.ticker = setTimeout(() => { this.run() }, this.refreshTime)
  }

  run() {
    Rails.ajax({
      url: "/ping?t=" + new Date().getTime() + "&s=" + document.body.dataset.page + "&v=" + !document[hidden],
      type: "GET",
      dataType: 'script',
      success: (data) => {
        this.tick()
      },
      error: (data) => {
        this.tick()
      }
    });
  }

  checkLockButton() {
    const table = document.getElementById("lobby-table")
    if (table) {
      const lockButton = document.getElementById("lock-lobby")
      if (lockButton) {
        const guestCount = table.dataset.guestCount
        lockButton.disabled = guestCount == 0
      }
    }
  }

}
