import { Controller } from "stimulus"
import Inputmask from "inputmask/dist/inputmask";

export default class extends Controller {
  masks = {
    'trainer-name' : { regex: '[A-Za-z0-9]{4,15}',  placeholder: '' },
    'trainer-code' : { regex: '\\d{4} \\d{4} \\d{4}',  placeholder: '' },
  }

  connect() {
    this.options = this.masks[this.data.get('type')]
    Inputmask(this.options).mask(this.element)

    this.changeListener = (event) => { this.checkValidity() }
    this.element.addEventListener('change', this.changeListener)
    this.checkValidity()
  }

  disconnect() {
    this.element.removeEventListener('change', this.changeListener)
    Inputmask.remove(this.element)
    this.element.inputmask = {}
  }

  checkValidity() {
    const value = this.element.value
    const isValid = value == '' || Inputmask.isValid(this.element.value, this.options)
    this.element.classList.toggle('is-invalid', !isValid)
  }
}
