import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    if ("IntersectionObserver" in window) {
      const config = {
        rootMargin: '0px 0px 50px 0px',
        threshold: 0
      }

      this.lazyloadImages = this.element.querySelectorAll("[bg]")
      this.imageObserver = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.loadImage(entry.target)
            observer.unobserve(entry.target)
          }
        })
      }, config)

      this.lazyloadImages.forEach((image) => {
        this.imageObserver.observe(image)
      })
    }
  }

  disconnect() {
    if (this.imageObserver) {
      this.lazyloadImages.forEach((image) =>{
        this.imageObserver.unobserve(image)
      })
    }
  }

  loadImage(el) {
    let asset = el.getAttribute("bg")
    if (!asset) return;
    el.style.backgroundImage = `url(${window.pokemonAssetPath}${asset}.png)`
  }
}
