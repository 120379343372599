import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["have", "haveCount", "need", "needCount", "searchInputMobile", "searchInputDesktop"]

  connect() {
    this.dex = document.querySelector('.dex')
    if (!this.dex) return;

    this.styles = document.getElementById("style-filters")
    this.shouldFilterHaveNeed = this.element.querySelector(".filter-have-need") != null
    this.filterType = "all"
    this.filterHave = false
    this.filterNeed = false
    this.searchString = ""

    if (!this.shouldFilterHaveNeed) {
      this.hideEmptyFilters()
    }

    this.updateFilterCountsListener = (event) => { this.updateCounts() }
    document.addEventListener("update-filter-counts", this.updateFilterCountsListener)

    const innerSearch = () => { this.filterFromSearch() }
    this.deb = debounce(innerSearch, 300)

    // Find active filter
    this.filterButton = this.element.querySelector(".filter-types .badge.active")
    if (this.filterButton) {
      this.filterType = this.filterButton.dataset.filter
    }

    // Set initial search on page load
    if (this.searchInputDesktopTarget && this.searchInputDesktopTarget.value != "") {
      this.searchString = this.searchInputDesktopTarget.value.toLowerCase()
      this.filterFromSearch()
    }

    this.filter()
  }

  disconnect() {
    document.removeEventListener("update-filter-counts", this.updateFilterCountsListener)
  }

  hideEmptyFilters() {
    this.element.querySelectorAll(".badge").forEach((button) => {
      const filter = button.dataset.filterAll
      const count = this.dex.querySelectorAll(filter).length
      if (count == 0) {
        button.classList.add("d-none")
      }
    })

    // Make sure a filter is selected
    const filterButton = this.element.querySelector(".filter-types .badge.active")
    if (filterButton && filterButton.classList.contains("d-none")) {
      const firstButton = this.element.querySelector(".filter-types .badge:not(.d-none)")
      if (firstButton) {
        filterButton.classList.remove("active")
        firstButton.classList.add("active")
      }
    }

  }

  run(event) {
    this.filterButton = event.target
    this.filterType = this.filterButton.dataset.filter
    this.filterPath = this.filterButton.dataset.filterPath

    if (this.filterPath == null) {
      this.filterPath = this.filterType
    }

    this.filter()

    this.element.querySelectorAll('.badge').forEach((element) => {
      element.classList.remove('active')
    })

    event.target.classList.add('active')

    let path = this.data.get("path")
    if (this.filterPath) {
      path += "/"
    }
    path += this.filterPath
    Turbolinks.controller.pushHistoryWithLocationAndRestorationIdentifier(path, Turbolinks.uuid())
  }

  search(event) {
    const search = event.target.value.toLowerCase()
    if (this.searchString == search) {
      return
    }
    this.searchString = search

    // Copy the value to the other search field
    if (event.target == this.searchInputDesktopTarget) {
      this.searchInputMobileTarget.value = this.searchInputDesktopTarget.value
    } else {
      this.searchInputDesktopTarget.value = this.searchInputMobileTarget.value
    }

    if (this.searchString == "") {
      this.filterFromSearch()
    } else {
      this.deb()
    }
  }

  filterFromSearch() {
    if (this.searchString == "") {
      // Show everything again
      this.dex.querySelectorAll(".p").forEach((elem) => {
        elem.classList.remove("d-none")
      })
    } else {
      // Check if searching by dex number
      let search = this.searchString
      if (search.length < 4 && /^[0-9]{1,3}$/.test(search)) {
        search = ('000' + search).slice(-4)
      }

      search = search.split(",").map(s => s.trim())
      search = search.filter(s => { return s != "" })

      // Search text in the element
      this.dex.querySelectorAll(".p").forEach((elem) => {
        const string = elem.textContent.toLowerCase()
        let match = false

        search.forEach((s) => {
          if (string.indexOf(s) !== -1) {
            match = true
            return
          }
        })

        elem.classList.toggle("d-none", !match)
      })
    }
  }

  filter() {
    const allFilter = this.filterAllCSS
    const haveFilter = this.filterHaveCSS

    let styles = `.filter-${this.filterType} { display: block; }`
    if (this.filterHave) {
      styles += `${haveFilter} { display: block; }`
    } else if (this.filterNeed) {
      styles += `${allFilter} { display: block; } ${haveFilter} { display: none; }`
    } else {
      styles += `${allFilter} { display: block; }`
    }
    styles += `.ju { display: block !important; }`

    // Remove anything that was just updated
    this.dex.querySelectorAll(".ju").forEach((elem) => {
      elem.classList.remove("ju")
    })

    this.styles.textContent = styles

    this.updateCounts()
  }

  updateCounts() {
    if (!this.shouldFilterHaveNeed) {
      return
    }

    const allFilter = this.filterAllCSS
    const haveFilter = this.filterHaveCSS

    const total = this.dex.querySelectorAll(allFilter).length
    const have  = this.dex.querySelectorAll(haveFilter).length
    const need  = total - have

    this.haveCountTarget.textContent = have
    this.needCountTarget.textContent = need
  }

  toggleHave(event) {
    event.preventDefault()
    this.filterHave = !this.filterHave
    if (this.filterHave && this.filterNeed) {
      this.filterNeed = false
    }

    this.updateHaveNeedFilter()
  }

  toggleNeed(event) {
    event.preventDefault()
    this.filterNeed = !this.filterNeed
    if (this.filterNeed && this.filterHave) {
      this.filterHave = false
    }

    this.updateHaveNeedFilter()
  }

  updateHaveNeedFilter() {
    this.filter()
    this.haveTarget.classList.toggle("active", this.filterHave)
    this.needTarget.classList.toggle("active", this.filterNeed)
  }

  get filterAllCSS() {
    if (this.filterButton) {
      return this.filterButton.dataset.filterAll
    }

    return ".p"
  }

  get filterHaveCSS() {
    if (this.filterButton) {
      return this.filterButton.dataset.filterHave
    }

    return ".hm, .hf"
  }
}
