import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "source" ]

  connect() {
    if (document.queryCommandSupported("copy")) {
      this.element.classList.add("clipboard--supported")
    }
  }

  copy() {
    let text = this.sourceTarget.value

    if (this.data.get("strip") == "true") {
      text = text.replace(/ /g, "")
    }

    // if (this.sourceTarget.inputmask) {
    //   // debugger;
    //   text = this.sourceTarget.inputmask.unmaskedvalue()
    // } else {
    //   text = this.sourceTarget.value
    // }

    this.copyToClipboard(text)
  }

  copyToClipboard(text) {
    const dummy = document.createElement("textarea")
    // dummy.style.display = 'none'
    document.body.appendChild(dummy)
    dummy.value = text
    dummy.select()
    document.execCommand("copy")
    document.body.removeChild(dummy)
  }
}
