import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.$tradeModal = jQuery('#trade-modal')
    this.buttons = this.element.querySelectorAll(".actions a")
    this.toggleButtonListener = (event) => { this.toggleButton(event) }

    this.buttons.forEach((button) => {
      button.addEventListener("click", this.toggleButtonListener)
    })
  }

  disconnect() {
    if (this.buttons) {
      this.buttons.forEach((button) => {
        document.removeEventListener("click", this.toggleButtonListener)
      })
    }
  }

  toggleButton(event) {
    event.preventDefault()

    const pokemon        = event.target.closest(".p")
    const pokemonClasses = pokemon.classList
    const id             = pokemon.getAttribute("pid")
    const buttonClasses  = event.target.classList

    let type   = ""
    let active = false

    if (buttonClasses.contains("bm")) {
      type = "hm"
      active = pokemonClasses.contains("hm") || pokemonClasses.contains("hg")
    } else if (buttonClasses.contains("bf")) {
      type = "hf"
      active = pokemonClasses.contains("hf")
    } else if (buttonClasses.contains("bl")) {
      type = "hl"
      active = pokemonClasses.contains("hl")
    } else if (buttonClasses.contains("bp")) {
      type = "hp"
      active = pokemonClasses.contains("hp")
    } else if (buttonClasses.contains("bt")) {
      // type = "ht"
      // active = pokemonClasses.contains("ht")
      this.openTradeModal(event)
      return
    }

    if (type != "") {
      Rails.ajax({
        url: this.element.dataset.action,
        type: "PATCH",
        data: `pid=${id}&t=${type}&a=${!active}`,
        dataType: "json",
        success: (data) => {
          // console.log('success', data)
          pokemonClasses.add("ju")
          pokemonClasses.toggle(type, !active)
          event.target.blur()
          Rails.fire(document, "update-filter-counts")
        },
        error: (data) => {
          console.log('error', data)
        }
      })
    }
  }

  openTradeModal(event) {
    const modal = document.getElementById('trade-modal')
    const pokemon = event.target.closest(".p")
    const id = pokemon.getAttribute("pid")
    const n1 = pokemon.querySelector(".n1").innerText
    const n2 = pokemon.querySelector(".n2").innerText
    const asset = pokemon.querySelector(".asset").getAttribute("bg")
    const tradeCount = pokemon.getAttribute("tc")

    modal.setAttribute("pid", id)
    modal.querySelector(".n1").innerText = n1
    modal.querySelector(".n2").innerText = n2
    modal.querySelector(".asset").style.backgroundImage = `url(${window.pokemonAssetPath}${asset}.png)`
    modal.querySelector("#trade-count").value = tradeCount || 0
    modal.querySelector("#trade-regular").checked = pokemon.classList.contains("wt")
    modal.querySelector("#trade-lucky").checked   = pokemon.classList.contains("wl")
    modal.querySelector("#trade-reroll").checked  = pokemon.classList.contains("wr")

    this.$tradeModal.modal('show')
  }

  saveTradeChanges(event) {
    this.toggleButtonDisabled(event.target, true)
    const modal = document.getElementById('trade-modal')
    const id = modal.getAttribute("pid")

    const wants       = document.getElementById("trade-regular").checked
    const wantsLucky  = document.getElementById("trade-lucky").checked
    const wantsReroll = document.getElementById("trade-reroll").checked
    const tradeCount  = parseInt(document.getElementById("trade-count").value, 10) || 0

    Rails.ajax({
      url: this.element.dataset.action,
      type: "PATCH",
      data: `pid=${id}&t=ht&wt=${wants}&wl=${wantsLucky}&wr=${wantsReroll}&tc=${tradeCount}`,
      dataType: "json",
      success: (data) => {

        const hasTrade = (wants || wantsLucky || wantsLucky || tradeCount > 0)
        const pokemon = this.element.querySelector(`.p[pid='${id}']`)
        if (pokemon) {
          pokemon.classList.toggle("ht", hasTrade)
          pokemon.classList.toggle("htc", tradeCount > 0)
          pokemon.classList.toggle("wt", wants)
          pokemon.classList.toggle("wl", wantsLucky)
          pokemon.classList.toggle("wr", wantsReroll)
          pokemon.setAttribute("tc", tradeCount)
          pokemon.classList.add("ju") // Just updated
        }

        // console.log('success', data)
        this.$tradeModal.modal('hide')
        this.toggleButtonDisabled(event.target, false)
      },
      error: (data) => {
        console.log('error', data)
        this.toggleButtonDisabled(event.target, false)
      }
    })
  }

  toggleButtonDisabled(elem, disable) {
    if (disable) {
      elem.disabled = true

      if (elem.dataset.disableWith) {
        elem.dataset.originalText = elem.innerText
        elem.innerText = elem.dataset.disableWith
      }
    } else {
      elem.disabled = false

      if (elem.dataset.disableWith) {
        elem.innerText = elem.dataset.originalText
      }
    }
  }

  selectTradeCount(event) {
    event.target.select()
  }

  keydownTradeCount(event) {
    const key = event.charCode || event.keyCode || 0;

    if (key == 13) { // Enter
      this.$tradeModal.find(".modal-footer button").click()
    }
  }
}
