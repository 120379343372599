// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import '../../assets/stylesheets/application';

window.Rails = require("@rails/ujs")
Rails.start()

require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "bootstrap"
import "controllers"

// For Mobile Safari css active events
document.addEventListener("touchstart", function(){}, true);

// var morphdom = require('morphdom').default;
// window.morphdom = morphdom;

window.replaceIt = function(html, dom_id) {
  const elem = document.getElementById(dom_id)
  if (elem) {
    elem.innerHTML = html;
  }
  // morphdom(document.getElementById(dom_id), html, {
  //   onBeforeElUpdated: function(fromEl, toEl) {
  //
  //     if (fromEl.classList.contains("clipboard--supported"))
  //       toEl.classList.add("clipboard--supported");
  //
  //     if (fromEl.tagName === "INPUT") {
  //       if (fromEl.type == "text" || fromEl.type == "checkbox") {
  //         return false;
  //       }
  //     }
  //
  //     return true;
  //   },
  //   // onBeforeElChildrenUpdated: function(fromEl, toEl) {
  //   //   if (fromEl.tagName == "FORM")
  //   //     return false;
  //   //   if (fromEl.tagName == "FORM" && fromEl.action.indexOf("confirm_trainer_code") > 0)
  //   //     return false;
  //   // }
  // });
};

window.debounce = function(func, wait = 100) {
  let timeout;
  return function(...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
}
